<template>
  <section>
    <p>{{ $t("orderType") }}: {{ $t(getOrderType) }}</p>
    <p v-if="orderTypeIsToHome">{{ $t("address") }}: {{ getAddress }}</p>
    <p>{{ $t("productsQty") }}: {{ getProductQty }}</p>
    <p>{{ $t("totalToPay") }}: {{ getTotalToPay }}</p>
  </section>
</template>
<script>
import {
  orderTypeName,
  orderTypeStoreGetters,
} from "@/store/modules/orderType/constNames";
import { formatPrice } from "@/helpers";
export default {
  name: "InfoOrder",
  props: {
    priceDomicileService: { type: Number },
  },
  methods: {
    formatPrice,
  },
  computed: {
    getOrderToSend() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TO_SEND];
    },
    getProductQty() {
      const { products } = this.getOrderToSend;
      return products.length > 1
        ? products.reduce((qty, product) => qty + product.qty, 0)
        : products[0].qty;
    },
    getOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    getAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS].street;
    },
    getTotalToPay() {
      return this.formatPrice(
        this.orderTypeIsToHome
          ? +this.$store.getters["cart/totalPrice"] + this.priceDomicileService
          : +this.$store.getters["cart/totalPrice"]
      );
    },
    orderTypeIsToHome() {
      return this.getOrderType === orderTypeName.AT_HOME;
    },
  },
};
</script>
